.team {
    background-color: var(--white);
    margin: 0 auto;
    padding: 100px 0 0 0;
}

.team .inner {
    width: calc(100% / 8 * 6 + 2px);
}

@media screen and (max-width: 768px) {
    .team {
        background-color: var(--white);
        margin: 0 auto;
        padding: 5px 0 0 0;
    }

    .team .inner {
        width: 100%;
    }
}
