.casehero {
    background-color: var(--black);
    color: var(--white);
    min-height: 100vh;
    padding-top: 54px;
}

.casehero .inner {
    margin: 100px auto 65px auto;
    max-width: calc(100% / 8 * 4);
}

.casehero .inner h4,
.casehero .inner h3 {
    font-family: var(--mono);
    font-weight: 400;
}

.casehero .inner h3 {
    font-size: 24px;
    text-transform: uppercase;
}

.casehero .inner p {
    color: var(--white);
}

.casehero .container {
    margin: 0 auto;
    padding: 50px 0 125px 0;
    width: calc(100% / 8 * 6 + 2px);
}

.casehero .container img {
    height: 100%;
    object-fit: contain;
    object-position: center;
    width: 100%;
}

.casehero .inner h1 {
    color: var(--green);
    margin-bottom: 35px;
}

@media screen and (min-width: 1500px) {
    .casehero .inner,
    .casehero .container {
        width: 800px;
    }
}

@media screen and (max-width: 768px) {
    .casehero {
        padding-top: unset;
        min-height: auto;
    }
    
    .casehero .inner {
        margin: 100px 18px 50px 18px;
        max-width: calc(100% - 36px);
    }
    
    .casehero .container {
        margin: 0;
        padding: 0 18px 50px 18px;
        width: 100%;
    }
    
    .casehero .container img {
        height: 100%;
        margin: 0;
        object-fit: contain;
        object-position: center;
        width: 100%;
    }
    
    .casehero .inner h1 {
        margin-bottom: 25px;
    }  
}