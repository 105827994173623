.home .inner .col:nth-child(1) a.button {
    background-color: var(--green);
}

.home .inner .col:nth-child(2) a.button {
    background-color: var(--pink);
}

.home .inner .col:nth-child(3) a.button {
    background-color: var(--blue);
}