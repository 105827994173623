.page-404 {
	background-color: var(--black);
	height: 100vh;
    width: 100vw;
}

.page-404 .inner {
	left: 50%;
	margin: 25px auto 0 auto;
	max-width: 500px;
	position: absolute;
    text-align: center;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
}

.page-404 .error-type {
	text-align: left;
}

.page-404 p {
	color: var(--white);
	font-size: 20px;
	margin: 5px 0 50px 0;
	text-align: center;
}

/* Glitch effect */
.error-type {
	animation: glitch 1s linear infinite;
	color: var(--white);
	font-family: var(--sans);
	font-size: 120px;
	margin: 0 0 0 122px;
}

@keyframes glitch {
  	2%, 64% {
    	transform: translate(2px,0) skew(0deg);
  	}
  	4%, 60% {
    	transform: translate(-2px,0) skew(0deg);
  	}
  	62% {
    	transform: translate(0,0) skew(5deg);
  	}
}

.error-type:before,
.error-type:after {
  	content: attr(title);
  	left: 0;
  	position: absolute;
}

.error-type:before {
	animation: glitchTop 3s ease-in infinite;
	color: var(--white);
	clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
	-webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
}

@keyframes glitchTop {
  	2%, 64% {
	    color: var(--white);
	    transform: translate(2px,-2px);
  	}
  	4%, 60% {
	    color: var(--white);
	    transform: translate(-2px,2px);
  	}
  	62% {
	    color: var(--blue);
	    transform: translate(13px,-1px) skew(-13deg);
  	}
}

.error-type:after {
	animation: glitchBotom 1.5s linear infinite;
	clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
	-webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
	color: var(--white);
}

@keyframes glitchBotom{
  	2%,64%{
  		color: var(--white);
    	transform: translate(-2px,0);
  	}
  	4%, 60%{
  		color: var(--white);
    	transform: translate(-2px,0);
  	}
  	62%{
  		color: var(--green);
    	transform: translate(-22px,5px) skew(21deg);
  	}
}
