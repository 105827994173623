.image {
    background-color: var(--black);
    color: var(--white);
}

.image .inner {
    margin: 125px auto;
}

.image .inner .container img {
    height: 100%;
    object-fit: contain;
    object-position: center;
    width: 100%;
}

@media screen and (max-width: 768px){
    .image .inner {
        margin: 70px auto;
    }
}