.member {
    background-color: var(--darkgrey);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    height: 300px;
    margin: 0 0 -4px 0;
    position: relative;
    text-align: left;
    transition: 1s;
    width: 25%;
}

.member .member-inner {
    bottom: 0;
    left: 0;
    max-width: 50vw;
    position: absolute;
    width: 100%;
}

.member .function,
.member .name {
    background-color: var(--white);
    color: var(--black);
    left: 0;
    opacity: 0;
    padding: 5px 10px;
    transition: 0s;
    width: max-content;
}

.member .name {
    margin: 0 0 -5px 0;
}

.member .function {
    bottom: 0;
    font-size: 16px;
}

.member .name {
    bottom: 29px;
    color: var(--blue);
    font-size: 18px;
    font-weight: 900;
}

.member:hover .function,
.member:hover .name {
    opacity: 1;
    transition: 0;
}


@media screen and (max-width: 1200px) {
    .member {
        height: 250px;
        width: 50%;
    }
}

@media screen and (max-width: 768px) {
    .member {
        height: 225px;
        width: 50%;
    }

    .member .function,
    .member .name {
        font-size: 12px;
        padding: 5px 10px;
    }

    .member .function {
        width: 100%;
    }

    .member .name {
        width: max-content;
    }

    .member .function {
        bottom: 0;
        font-size: 12px;
    }

    .member .name {
        bottom: 20px;
        color: var(--blue);
        font-size: 14px;
        font-weight: 900;
    }
}

@media screen and (max-width: 500px) {
    .member {
        height: 200px;
    }
}
