.cols {
    background-color: var(--white);
    padding: 100px 0;
    text-align: left;
}

.cols .inner {
    display: flex;
}

.cols .inner .col {
    margin: 0 25px;
    width: 100%;
}

.cols .inner .col:first-of-type {
    margin: 0 25px 0 0;
    width: 100%;
}

.cols .inner .col:last-of-type {
    margin: 0 0 0 25px;
    width: 100%;
}

.cols .inner .col a {
    color: var(--blue);
    display: block;
    margin: 10px 0;
    text-decoration: underline;
}

.cols .inner .col a:hover {
    color: var(--orange);
    text-decoration: underline;
}

.cols .inner .col a.button:hover,
.cols .inner .col a.button {
    color: var(--white);
    display: inline-block;
    margin: 15px 0 0 0;
    text-decoration: unset;
}

.cols .inner .col h2 {
    line-height: 1em;
    margin: 0 0 25px 0;
}

@media screen and (max-width: 768px) {
    .cols {
        padding: 35px 0;
    }
    
    .cols .inner {
        display: block;
    }
    
    .cols .inner .col,
    .cols .inner .col:first-of-type {
        margin: 0 20px 35px 20px;
        width: calc(100% - 40px);
    }

    .cols .inner .col:last-of-type {
        margin: 0 20px;
        width: calc(100% - 40px);
    }
    
    .cols .inner .col a.button:hover,
    .cols .inner .col a.button {
        margin: 10px 0 0 0;
    }
    
    .cols .inner .col h2 {
        line-height: 1em;
        margin: 0 0 20px 0;
    }
}