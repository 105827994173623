.filter {
    background-color: var(--black);
    padding: 0 0 50px 0;
}

.filter .inner {
    margin: 0 auto;
}

.filter .inner .col {
    display: inline-block;
    margin: 0 20px 0 0;
}

.filter .inner .col:last-of-type {
    margin: 0;
}

.filter .inner a.button {
    background-color: var(--black);
    border: 2px solid var(--darkgrey);
}

.filter .inner a.button.active {
    background-color: var(--blue);
    border: 2px solid var(--blue);
}

.filter .inner a.button.all.active {
    background-color: var(--orange);
    border: 2px solid var(--orange);
}

.filter .inner a.button.platforms.active {
    background-color: var(--green);
    border: 2px solid var(--green);
}

.filter .inner a.button.refactoring.active {
    background-color: var(--pink);
    border: 2px solid var(--pink);
}

.filter .inner a.button.aviation.active {
    background-color: var(--avitms);
    border: 2px solid var(--avitms);
}

.filter .inner a.button.strategy.active {
    background-color: var(--blue);
    border: 2px solid var(--blue);
}

@media screen and (max-width: 768px) { 
    .filter {
        padding: 40px 0 25px 0;
    }

    .filter .inner {
        margin: 0 20px;
    }
    
    .filter .inner .col {
        display: inline-block;
        height: 50px;
        margin: 0 10px 0 0;
    }

    .filter .inner .col:last-of-type {
        margin: 0;
    }
}