.tag {
    color: var(--darkergrey);
    display: flex;
    font-size: 20px;
}

@media screen and (max-width: 768px) {
    .tag {
        font-size: 16px;
    }
}
