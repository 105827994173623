.quote {
    background-color: var(--black);
    color: var(--white);
    position: relative;
}

.quote .inner {
    max-width: calc(100% / 8 * 4);
    margin: 125px auto;
}

.quote .inner h3 {
    border: 2px solid var(--darkergrey);
    margin: 0;
    overflow: hidden;
    padding: 50px;
}

.quote .inner .quoting {
    margin: 0 auto;
    position: relative;
}

.quote .inner .quoting .container {
    display: flex;
    margin: 0 auto;
    width: 100%;
}

.quote .inner .quoting .arrow {
    border: 40px solid var(--darkergrey); 
    border-bottom-color: var(--darkergrey); 
    border-right-style: solid; 
    border-top: none; 
    border-bottom-color: transparent; 
    border-left-color: transparent;
    left: 35px;
    margin: 0 0 0 -40px;
    line-height: 0;
    height: 0; 
    position: absolute; 
    top: 0; 
    width: 0;
    display: inline-block;
}

.quote .inner .quoting .arrow:after {
    content:'';
    position: absolute;
    width: 0;
    height: 0;
    border: 46px dashed var(--black); 
    top: -11px;
    left: -54px; 
    border-right-style: solid; 
    border-top: none; 
    border-bottom-color: transparent; 
    border-left-color: transparent;
    display: inline-block;
}

.quote .inner .quoting img {
    height: 100px;
    margin: 25px 25px 0 100px;
    object-fit: cover;
    object-position: center;
    width: 100px;
    display: inline-block;
}

.quote .inner .quoting .text {
    margin: auto 0;
    padding-top: 25px;
    text-align: left;
    position: relative;
    display: inline-block;
}

.quote .inner .quoting .text p {
    color: var(--white);
    margin: 0;
}

.quote .inner .quoting .text p.small {
    color: var(--green);
    margin: 0;
}

@media screen and (min-width: 1500px) {
    .quote .inner {
        width: 800px;
    }
}

@media screen and (max-width: 768px) { 
    .quote .inner {
        margin: 0 20px;
        max-width: 100%;
        padding: 60px 0;
        width: calc(100% - 40px);
    }
    
    .quote .inner h3 {
        font-size: 18px;
        font-weight: 400;
        padding: 20px;
    }
    
    .quote .inner .quoting .arrow {
        border: 20px solid var(--darkgrey); 
        border-bottom-color: var(--darkgrey); 
        border-right-style: solid; 
        border-top: none; 
        border-bottom-color: transparent; 
        border-left-color: transparent;
        left: 35px;
        margin: 0 0 0 -40px;
        line-height: 0;
        height: 0; 
        position: absolute; 
        top: 0; 
        width: 0;
        display: inline-block;
    }
    
    .quote .inner .quoting .arrow:after {
        content:'';
        position: absolute;
        width: 0;
        height: 0;
        border: 26px dashed var(--black); 
        top: -11px;
        left: -34px; 
        border-right-style: solid; 
        border-top: none; 
        border-bottom-color: transparent; 
        border-left-color: transparent;
        display: inline-block;
    }
    
    .quote .inner .quoting img {
        margin: 20px 20px 0 55px;
    }
    
    .quote .inner .quoting .text {
        padding-top: 20px;
    }

    .quote .inner .quoting .text p {
        font-size: 14px;
    }

    .quote .inner .quoting .text p.small {
        font-size: 10px;
    }
}