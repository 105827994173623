
nav {
    color: var(--white);
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 4;
    transition: background-color 0.1s;
}

nav.mob {
    display: none;
}

nav.desk {
    background-color: var(--darkgrey);
}

nav.desk.white .tabs .tab.logo-tab {
    background-color: var(--white);
}

nav .tabs {
    background-color: var(--darkgrey);
    display: flex;
    margin: 0 auto;
    width: 100%;
}

nav .tabs .tab {
    background-color: var(--darkgrey);
    display: flex;
    padding: 13px 25px;
    text-align: left;
    transition: 0.1s;
    width: calc(100% - 50px);
}

nav .tabs .tab.active-dark {
    background-color: #191A1C !important;
}

nav.white .tabs .tab.active-light {
    background-color: #FFF !important;
}

nav .tabs .tab:first-child,
nav .tabs .tab.clickable {
    border-right: 2px solid var(--black);
    cursor: pointer;
    min-width: max-content;
    min-width: -moz-max-content;
    min-width: -webkit-max-content;
    transition: 0.1s;
}

nav .tabs .tab.active {
    background-color: var(--black);
}

nav .tabs .tab:last-child {
    border-right: none;
    min-width: max-content;
    min-width: -moz-max-content;
    min-width: -webkit-max-content;
}

nav .logo {
    background-image: url('../../../assets/images/logo/logo-licht.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 25px;
    margin-bottom: -2px;
    width: 85px;
}

nav .tabs .tab .title {
    font-size: 18px;
    margin: auto 0;
    transition: 0.5s;
    width: 100%;
}

nav .tabs .tab .switch {
    display: flex;
    margin: 0 0 0 auto;
}

nav .tabs .tab .switch .lang {
    font-size: 15px;
    margin: auto 0;
}

nav .tabs .tab .switch .toggle {
    margin: auto 10px;
}

/* Nav small */
nav.small .tabs .tab .title {
    font-size: 16px;
    transition: 0.5s;
}

/* Nav white */
nav.white {
    background-color: var(--lightgrey);
}

nav.white .tabs .tab{
    background-color: var(--lightgrey) !important;
}

nav.white .logo {
    background-image: url('../../../assets/images/logo/logo-donker.png');
}

nav.white .tabs .tab .title {
    color: var(--black);
}

nav.white .tabs .tab.clickable {
    border-right: 2px solid var(--white);
    cursor: pointer;
    min-width: max-content;
    min-width: -moz-max-content;
    min-width: -webkit-max-content;
}

nav.white .tabs .tab.clickable.active {
    background-color: var(--white) !important;
}

nav.white .tabs .tab .switch {
    display: flex;
    margin: 0 0 0 auto;
}

nav.white .tabs .tab .switch .lang {
    color: var(--black);
    font-size: 15px;
    margin: auto 0;
}

nav.white .tabs .tab .switch .toggle {
    margin: auto 10px;
}

nav.white .tabs .tab .switch .slider {
    background-color: var(--black);
    transition: .4s;
    -webkit-transition: .4s;
}

@media screen and (min-width: 1500px) {
    nav .tabs {
        max-width: 1450px;
    }
}

@media screen and (max-width: 768px) {
    nav.desk {
        display: none;
    }

    nav.mob {
        background-color: var(--black);
        display: flex;
        height: auto;
        padding: 15px 18px;
        width: 100%;
    }

    nav.mob .left {
        margin: auto 0;
        width: 100%;
    }

    nav.mob .left .tab a {
        width: max-content;
    }

    nav.mob .left .tab .logo {
        height: 20px;
        width: 88px;
    }

    nav.mob .open {
        background-color: var(--black);
        height: 100vh;
        left: 0;
        position: fixed;
        top: 0;
        width: 100vw;
        z-index: 999;
    }

    nav.mob .open .items {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    nav.mob .open .items div {
        margin: 25px 0;
    }

    nav.mob .open .items div a {
        font-size: var(--h3);
    }

    nav.mob .open .items .close {
        border-radius: 50%;
        height: 50px;
        margin: 0 0 5px 0;
        padding: 10px;
    }

    nav.mob .switch {
        display: flex;
        margin: auto 20px auto 0;
        min-width: max-content;
        min-width: -moz-max-content;
        min-width: -webkit-max-content;
    }

    nav.mob .switch .lang:first-of-type {
        margin: 0 8px 0 0;
    }

    nav.mob .switch .lang:last-of-type {
        margin: 0 0 0 8px;
    }

    nav.mob .switch .toggle {
        margin: 4px 0 0 0;
    }

    nav.mob img {
        margin: 0 0 -4px 0;
    }
}
