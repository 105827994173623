* {
  box-sizing: border-box;
}

html,
body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  min-height: 100vh;
  text-rendering: optimizeLegibility;
  width: 100%;
  word-break: break-word;
}

.page {
  position: relative;
  width: 100%;
}

.page .inner {
  margin: 0 auto;
  max-width: 1400px;
  position: relative;
}

/* Switch toggle input */

.toggle {
  display: inline-block;
  height: 10px;
  position: relative;
  width: 30px;
}

.toggle input {
  height: 0;
  opacity: 0;
  width: 0;
}

.slider {
  background-color: var(--white);
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .4s;
  -webkit-transition: .4s;
}

.slider:before {
  background-color: var(--orange);
  bottom: -4px;
  content: "";
  height: 18px;
  left: 0;
  position: absolute;
  transition: .4s;
  -webkit-transition: .4s;
  width: 18px;
}

input:checked + .slider {
  background-color: var(--white);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--white);
}

input:checked + .slider:before {
  transform: translateX(13px);
  -ms-transform: translateX(13px);
  -webkit-transform: translateX(13px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.button {
  background-color: var(--orange);
  margin: 0 auto;
  padding: 8px 18px;
}

@media screen and (max-width: 768px) {
  .page .inner {
    margin: 0;
    padding: unset;
    width: unset;
  }

  .button {
    font-size: 16px;
    padding: 6px 12px;
  }
}
