footer {
    background-color: var(--darkgrey);
    color: var(--white);
    width: 100%;
}

footer .inner {
    display: flex;
    margin: 0 auto;
    padding: 100px 0;
    text-align: left;
    width: calc(100% / 8 * 6 + 2px);
}

footer .inner {
    width: 100%;
}

footer .inner .col {
    width: 100%;
}

footer .inner .col.left {
    display: block;
    margin: auto 20px auto 0;
}

footer .inner .col.center-left,
footer .inner .col.center-right {
    display: flex;
    margin: auto 20px auto 0;
}

footer .inner .col.right {
    display: block;
    margin:  0 20px 0 0 ;
}

footer .inner .col img {
    height: 110px;
    object-fit: cover;
    object-position: center;
    width: 110px;
}

footer .inner .col.center-left .container,
footer .inner .col.center-right .container {
    background-color: none;
    margin: auto 0 auto 25px;
}

footer .inner .col .container p {
    font-size: var(--small);
}

footer .inner .col .container h4,
footer .inner .col.right .container h3,
footer .inner .col.left .container h3 {
    margin-top: 0;
}

footer .inner .col .container p,
footer .inner .col.right .container p,
footer .inner .col.left .container p {
    margin-bottom: 0;
}

footer .inner .col a {
    color: var(--orange);
    text-decoration: underline;
}

footer .inner .col.center-left a,
footer .inner .col.center-right a {
    font-size: var(--small);
    margin: 40px 0 0 0;
}

@media screen and (max-width: 768px) {
    footer {
        padding: 60px 20px 20px 20px;
        width: 100%;
    }

    footer .inner {
        display: block;
    }

    footer .inner .col.left {
        margin: auto 0 35px 0;
    }

    footer .inner .col.center-left {
        margin: auto 0 20px 0;
    }

    footer .inner .col.center-right {
        margin: auto 0 35px 0;
    }

    footer .inner .col.right {
        margin: auto 0 35px 0;
    }
    
    footer .inner .col img {
        height: 110px;
        object-fit: cover;
        object-position: center;
        width: 110px;
    }
    
    footer .inner .col.center-left .container,
    footer .inner .col.center-right .container {
        margin: auto 0 auto 20px;
    } 
}