.thumbnail {
    background-color: var(--white);
    margin: 0;
    padding: 50px 0;
    position: relative;
}

.thumbnail .inner {
    color: var(--black);
    display: flex;
    margin: 50px auto;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.thumbnail .inner .empty {
    width: calc(100% / 8 * 1);
}

.thumbnail .inner.reversed {
    text-align: right;
}

.thumbnail .inner .img-side {
    width: 50%;
}

.thumbnail .inner .img {
    height: auto;
    width: 100%;
}

.thumbnail .inner .text {
    margin: auto 50px auto 0;
    text-align: left;
    width: 33%;
}

.thumbnail .inner.reversed .text {
    margin: auto 0 auto 50px;
}

.thumbnail .inner.reversed .text .text-inner {
    float: left;
    width: calc(100% / 8 * 7 + 2px);
}

.thumbnail .inner .text h4 {
    font-family: var(--mono);
    font-weight: 500;
    margin: 0;
    text-transform: uppercase;
}

.thumbnail .inner .text h2 {
    font-size: 46px;
    line-height: 52px;
    margin: 15px 0;
}

.thumbnail .inner .arrow {
    background-image: url('../../../assets/svgs/arrow_alt.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 30px;
    transition: 0.2s;
    width: 30px;
}

.thumbnail .inner:hover .arrow {
    margin-left: 20px;
    transition: 0.2s;
    transition-delay: 0.2s;
}

.thumbnail .inner.normal .text {
    text-align: right;
}

.thumbnail .inner.normal .arrow {
    margin: 0 0 0 auto;
    right: 0;
}

.thumbnail .inner.normal:hover .arrow {
    margin: 0 -20px 0 auto;
    transition: 0.2s;
    transition-delay: 0.2s;
}

@media screen and (min-width: 1500px) {
    .thumbnail .inner {
        width: 1400px;
    }
}

@media screen and (max-width: 1200px) {
    .thumbnail .inner .text,
    .thumbnail .inner .img-side {
        width: 50%;
    }

    .thumbnail .inner .text h2 {
        font-size: 40px;
        line-height: 46px;
        margin: 15px 0;
    }
}

@media screen and (max-width: 1000px) {
    .thumbnail .inner .text h2 {
        font-size: 36px;
        line-height: 42px;
        margin: 15px 0;
    }
}

@media screen and (max-width: 768px) {
    .thumbnail {
        padding: 20px 0;
    }

    .thumbnail .inner {
        display: block;
        margin: 20px 0;
        padding: 0;
        width: 100%
    }

    .thumbnail .inner .text,
    .thumbnail .inner .img-side {
        width: 100%;
    }

    .thumbnail .inner .text {
        padding: 0 20px;
        margin: auto 0;
    }

    .thumbnail .inner.reversed .text {
        margin: auto 0;
    }

    .thumbnail .inner .text .text-inner {
        min-width: unset;
        max-width: unset;
    }

    .thumbnail .inner.reversed .text .text-inner {
        max-width: unset;
    }

    .thumbnail .inner .text h4 {
        margin: 20px 0 0 0;
    }

    .thumbnail .inner .text h2 {
        font-size: 26px;
        line-height: 32px;
    }

    .thumbnail .inner .arrow {
        height: 20px;
        margin: 0 0 20px 0;
        width: 20px;
    }
}
