.content {
    background-color: var(--white);
    padding: 100px 0;
    width: 100%;
}

.content .inner {
	display: flex;
    margin: 0 auto;
    text-align: left;
    width: calc(100% / 8 * 6 + 2px);
    z-index: 3;
}

.content .inner .side {
	background-color: var(--lightgrey);
	padding: 50px;
    position: relative;
	width: 100%
}

.content .inner .side.sharpen {
    height: 600px;
}

.content .inner .side.left {
    background: var(--darkgrey);
    height: 600px;
    padding: 0;
}

.content .inner .side.left img {
    height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%;
}

.content .inner .side.left h2 {
    color: var(--white);
    opacity: 0.8;
    text-align: center;
}

.content .inner .side.left p {
    color: var(--white);
    opacity: 0.9;
    text-align: center;
}

.content .inner .side.right {
	margin: auto 0 auto -50px;
	height: max-content;
	height: -moz-max-content;
	height: -webkit-max-content;
	padding: 35px 50px;
	z-index: 1;
}

.content .inner .side h2 {
    margin: 0 0 20px 0;
}

@media screen and (min-width: 1500px) {
    .content .inner {
        width: 1400px;
    }    
}

@media screen and (max-width: 768px) {
    .content {
        padding: 0;
    }
    
    .content .inner {
        display: block;
        width: 100%;
    }

    .content .inner .side p:last-of-type {
        margin-bottom: 0;
    }
    
    .content .inner .side {
        padding: 20px;
    }
    
    .content .inner .side.sharpen {
        height: 250px;
    }
    
    .content .inner .side.left {
        height: 250px;
        width: 100%;
    }
    
    .content .inner .side.right {
        margin: auto 0;
        padding: 30px 20px 35px 20px;
        z-index: 1;
    }

}