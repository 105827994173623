@font-face {
  font-family: 'Nurom';
  src: url('./assets/fonts/Nurom-Bold.ttf');
}

@font-face {
  font-family: 'Droid Sans Mono';
  src: url('./assets/fonts/DroidSansMono.ttf');
}

.nextgear-theme {
  --black: #191A1C;
  --darkgrey: #28292C;
  --darkgrey: #28292C;
  --darkergrey: #3A3C40;
  --blue: #0C52D2;
  --green: #20BF55;
  --orange: #FD6721;
  --pink: #DF57BC;
  --grey: #DCDEE2;
  --lightgrey: #F3F5F8;
  --white: #FFFFFF;
  --big: 56px;
  --h1: 50px;
  --h2: 38px;
  --h3: 26px;
  --h4: 20px;
  --text: 16px;
  --small: 14px;
  --mono: 'Droid Sans Mono', sans-serif;
  --sans: 'Nurom', sans-serif;

  --avitms: #1B8EF9;
  --fastlane: #2EBDA2;
  --finalyser: #375EEC;
  --fxdc: #FD6048;
  --kugis: #D6B26C;
  --nextxs: #00C500;
  --thenewworker: #F8D513;
  --voyp: #6EC1E3;
}

.kugis .casehero .inner h1,
.kugis .info .inner h2,
.kugis .quoting .small,
.kugis footer a {
  color: var(--kugis) !important;
}

.kugis nav .slider:before {
  background-color: var(--kugis);
}

.voyp .casehero .inner h1,
.voyp .info .inner h2,
.voyp .quoting .small,
.voyp footer a {
  color: var(--voyp) !important;
}

.voyp nav .slider:before {
  background-color: var(--voyp);
}

.finalyser .casehero .inner h1,
.finalyser .info .inner h2,
.finalyser .quoting .small,
.finalyser footer a {
  color: var(--finalyser) !important;
}

.finalyser nav .slider:before {
  background-color: var(--finalyser);
}

.avitms .casehero .inner h1,
.avitms .info .inner h2,
.avitms .quoting .small,
.avitms footer a {
  color: var(--avitms) !important;
}

.avitms nav .slider:before {
  background-color: var(--avitms);
}

.fastlane .casehero .inner h1,
.fastlane .info .inner h2,
.fastlane .quoting .small,
.fastlane footer a {
  color: var(--fastlane) !important;
}

.fastlane nav .slider:before {
  background-color: var(--fastlane);
}

.fxdc .casehero .inner h1,
.fxdc .info .inner h2,
.fxdc .quoting .small,
.fxdc footer a {
  color: var(--fxdc) !important;
}

.fxdc nav .slider:before {
  background-color: var(--fxdc);
}

.thenewworker .casehero .inner h1,
.thenewworker .info .inner h2,
.thenewworker .quoting .small,
.thenewworker footer a {
  color: var(--thenewworker) !important;
}

.thenewworker nav .slider:before {
  background-color: var(--thenewworker);
}

.nextxs .casehero .inner h1,
.nextxs .info .inner h2,
.nextxs .quoting .small,
.nextxs footer a {
  color: var(--nextxs) !important;
}

.nextxs nav .slider:before {
  background-color: var(--nextxs);
}

.cases footer a {
  color: var(--orange) !important;
}

.cases nav .slider:before {
  background-color: var(--orange);
}

.aviation footer a {
  color: var(--avitms) !important;
}

.aviation nav .slider:before {
  background-color: var(--avitms);
}


.refactoring footer a {
  color: var(--pink) !important;
}

.refactoring nav .slider:before {
  background-color: var(--pink);
}

.platforms footer a {
  color: var(--green) !important;
}

.platforms nav .slider:before {
  background-color: var(--green);
}

.platforms footer a {
  color: var(--green) !important;
}

.platforms nav .slider:before {
  background-color: var(--green);
}

.strategy footer a {
  color: var(--blue) !important;
}

.strategy nav .slider:before {
  background-color: var(--blue);
}

html,
body {
  background-color: var(--black);
  font-family: var(--mono);
}

a {
  color: var(--white);
  font-family: var(--mono);
  text-decoration: none;
  white-space: pre-line;
}

h1,h2,h3,h4 {
  font-family: var(--sans);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  white-space: pre-line;
}

h1 {
  font-size: var(--h1);
  line-height: 1.418em;
}

h2 {
  font-size: var(--h2);
}

h3 {
  font-size: var(--h3);
}

h4 {
  font-size: var(--h4);
  margin-bottom: 10px;
}

p {
  font-family: var(--mono);
  font-size: var(--text);
  line-height: 1.618em;
  white-space: pre-line;
}

p.small {
  font-family: var(--mono);
  font-size: var(--small);
  line-height: 1.618em;
}

@media screen and (max-width: 768px){
  .nextgear-theme {
    --big: 38px;
    --h1: 38px;
    --h2: 30px;
    --h3: 24px;
    --h4: 20px;
    --text: 16px;
    --small: 14px;
    --mono: 'Droid Sans Mono', sans-serif;
    --sans: 'Nurom', sans-serif;
  }

  html,
  body {
    background-color: var(--black);
    font-family: var(--mono);
  }

  a {
    color: var(--white);
    font-family: var(--mono);
    text-decoration: none;
    white-space: pre-line;
  }

  h1,h2,h3,h4 {
    font-family: var(--sans);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    white-space: pre-line;
  }

  h1 {
    font-size: var(--h1);
    line-height: 1.418em;
  }

  h2 {
    font-size: var(--h2);
  }

  h3 {
    font-size: var(--h3);
  }

  h4 {
    font-size: var(--h4);
    margin-bottom: 10px;
  }

  p {
    font-family: var(--mono);
    font-size: var(--text);
    line-height: 1.618em;
    white-space: pre-line;
  }

  p.small {
    font-family: var(--mono);
    font-size: var(--small);
    line-height: 1.618em;
  }
}
