.hero {
    background-color: var(--black);
    margin: 0 auto;
    max-width: 1450px;
    width: 100%;
}

.hero.small {
    padding: 125px 0 0 0;
}

.hero .inner {
    border-left: 2px solid var(--darkgrey);
    height: 100%;
    min-height: 100vh;
    text-align: left;
}

.hero .inner .code {
    color: var(--white);
    margin: auto 0;
    padding: 0 0 0 75px;
    position: absolute;
    top: calc(50%);
    transform: translateY(-50%);
}

.hero .inner .code .typed {
    display: inline-block;
}

.hero .inner .code .typed h1 {
    color: var(--white);
    font-size: var(--big);
    margin: 0;
}

.hero .inner .code .typed h1 span .typed-cursor {
    color: var(--white);
}

.hero .inner .code h4 {
    font-family: var(--mono);
    font-weight: 500;
    line-height: 1.618em;
    margin: 15px 0;
    max-width: 650px;
}

/* Hero small */

.hero.small .inner {
    border: none;
    height: 150px;
    min-height: unset;
}

.hero.small .inner .code {
    max-width: 100%;
    padding: 0;
}

.hero.small .inner .code,
.hero.small .inner .code .typed,
.hero.small .inner .code .typed h1 {
    text-align: center;
    width: 100%;
}

@media screen and (max-width: 768px) {

    .hero .inner {
        border: none;
    }

    .hero .inner .code {
        max-width: 750px;
        padding: 0 20px;
    }

    .hero .inner .code .typed {
        margin: 10px 0;
    }

    .hero .inner .code h4 {
        font-size: 16px;
    }

    .hero .inner .code h1 {
        line-height: 1.2em;
        padding: 5px 0;
    }

    /* Hero small */
    .hero.small {
        margin: 95px 0 0 0;
        padding: 0;
        width: 100vw;
    }

    .hero.small .inner {
        border: none;
        height: auto;
        margin: 0;
        min-height: unset;
    }

    .hero.small .inner .code {
        max-width: 100%;
        padding: 0;
        position: relative;
        top: unset;
        transform: unset;
        width: unset;
    }

    .hero .inner .code h1 {
        line-height: 1.2em;
    }

    .hero.small .inner .code,
    .hero.small .inner .code .typed,
    .hero.small .inner .code .typed h1 {
        text-align: center;
    }
}