.clients {
    background-color: var(--black);
}

.clients .inner {
    padding: 100px 0;
}

.clients .inner {
    color: var(--white);
    margin: 0 auto;
    width: calc(100% / 8 * 6 + 2px);
}

.clients .inner .row {
    display: flex;
    position: relative;
}

.clients .inner .row .col {
    margin: auto 50px;
    padding: 20px 15px;
    position: relative;
    width: 100%;
}

.clients .inner .row .col img {
    height: auto;
    margin: auto 0;
    width: 100%;
}

@media screen and (max-width: 768px) { 
    .clients {
        background-color: var(--black);
    }

    .clients .inner {
        padding: 35px 0;
        width: 100%;
    }
    

    .clients .inner .row {
        display: block;
        position: relative;
    }

    .clients .inner h2 {
        margin: 0 0 20px 0;
    }

    .clients .inner .row .col {
        display: inline-block;
        margin: auto 20px;
        padding: 10px 15px;
        position: relative;
        vertical-align: middle;
        width: calc(50% - 60px);
    }

    .clients .inner .row img {
        height: auto;
        margin: 50px;
        width: calc(50% - 100px);
    }
    
}