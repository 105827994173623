.info {
    background-color: var(--white);
    color: var(--black);
    position: relative;
    text-align: left;
}

.info.bullet .bullet-item {
    display: flex;
}

.info a {
    color: black;
    text-decoration: underline;
}

.info.bullet .bullet-item .bullet-dot {
    margin-right: 4px;
}

.info .inner {
    max-width: calc(100% / 8 * 4);
    margin: 0 auto;
    padding: 100px 0;
}

.info .inner h2 {
    color: var(--green);
    margin: 10px 0;
}


.info .inner.no-padding-top {
    padding: 0 0 100px 0;
}

.info .inner.no-padding-top h2 {
    margin: -30px 0 10px 0;
}

@media screen and (min-width: 1500px) {
    .info .inner {
        width: 800px;
    }
}

@media screen and (max-width: 768px) {
    .info .inner {
        max-width: calc(100% - 36px);
        margin: 0 auto;
        padding: 35px 0;
    }

    .info .inner.no-padding-top {
        padding: 0 0 36px 0;
    }

    .info .inner h2 {
        margin: 10px 0 0 0;
    }
}