.ratio {
	background-color: var(--white);
	display: grid;
	padding: 0 0 100px 0;
  }
.ratio > * {
	grid-area: 1/1;
}
  
.ratio div.box {
	align-items: center;
	display: flex;
	justify-content: center;
}

.collage-container h2 {
	display: none;
}

.collage {
	background-color: var(--white)
}

.collage .inner {
	height: 100%;
	padding: 0 50px;
	position: relative;
	width: calc(100% - 100px);
	display: flex;
}

.collage .piece {
	background-color: var(--lightgrey);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	font-size: 0;
	position: absolute;
}

.collage .piece.one {
	background-image: url(../../assets/images/collage/meeting.jpg);
	height: 35%;
	left: 0;
	width: calc(30% - 50px);
}

.collage .piece.two {
	background-image: url(../../assets/images/collage/green.jpg);
	height: 35%;
	left: calc(30%);
	width: calc(20% - 50px);
}

.collage .piece.three {
	background-image: url(../../assets/images/collage/coffee.jpg);
	height: 55%;
	left: calc(50%);
	top: 0;
	width: 20%;
}

.collage .piece.four {
	background-image: url(../../assets/images/collage/monster.jpg);
	right: 0;
	height: 55%;
	top: 0;
	width: calc(30% - 50px);
}

.collage .piece.five {
	background-color: var(--white);
	height: calc(35% - 100px);
	top: calc(35% + 50px);
	left: calc(25%);
	width: calc(25% - 50px);
}

.collage .piece.five .logo {
	background-image: url('../../assets/images/logo/logo-donker.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	height: 100%;
	margin: 0 auto;
	width: 100%;
}

.collage .piece.six{
	background-image: url(../../assets/images/collage/sign.jpg);
	bottom: 50px;
	height: calc(65% - 100px);
	left: 0;
	width: calc(25% - 50px);
}

.collage .piece.seven {
	background-image: url(../../assets/images/collage/neon.jpg);
	bottom: 0;
	height: 30%;
	left: calc(25%);
	width: calc(25% - 50px);
}

.collage .piece.eight {
	background-image: url(../../assets/images/collage/office_1.jpg);
	bottom: 0;
	height: calc(45% - 50px);
	left: calc(50%);
	width: calc(30% - 50px);
}

.collage .piece.nine {
	background-image: url(../../assets/images/collage/office_2.jpg);
	bottom: 50px;
	height: calc(45% - 100px);
	left: calc(80%);
	width: 20%;
}

@media screen and (min-width: 1500px) {
    .collage .inner {
        max-height: 700px;
    }

	.ratio {
		height: 825px;
	}

	.ratio div.box {
		height: 700px;
	}
}


@media screen and (max-width: 1000px) {
	
	.collage .inner {
		padding: 0 20px;
		width: calc(100% - 40px);
	}
	
	.collage .piece.one {
		height: 35%;
		left: 0;
		width: calc(30% - 20px);
	}
	
	.collage .piece.two {
		height: 35%;
		left: calc(30%);
		width: calc(20% - 20px);
	}
	
	.collage .piece.three {
		height: 55%;
		left: calc(50%);
		top: 0;
		width: 20%;
	}
	
	.collage .piece.four {
		right: 0;
		height: 55%;
		top: 0;
		width: calc(30% - 20px);
	}
	
	.collage .piece.five {
		height: calc(35% - 40px);
		top: calc(35% + 20px);
		width: calc(25% - 20px);
	}
	
	.collage .piece.six{
		bottom: 20px;
		height: calc(65% - 40px);
		width: calc(25% - 20px);
	}
	
	.collage .piece.seven {
		width: calc(25% - 20px);
	}
	
	.collage .piece.eight {
		height: calc(45% - 20px);
		width: calc(30% - 20px);
	}
	
	.collage .piece.nine {
		bottom: 20px;
		height: calc(45% - 40px);
	}
}

@media screen and (max-width: 768px) {

	.collage-container {
		display: none;
	}

	.ratio {
		background-color: var(--white);
		display: grid;
		padding: 0;
		margin: 0 0 -1px 0;
	}

	.collage-container {
		background-color: var(--white);
		padding: 1px;
	}

	.collage-container h2 {
		display: block;
		line-height: 1.2em;
		margin: 35px 0 -10px 20px;
		text-align: left;
	}

	.collage .inner h3 {
		display: none;
	}

	.collage .inner {
		padding: 0;
		width: 100%;
	}

	.collage .piece.four,
	.collage .piece.five,
	.collage .piece.eight {
		display: none;
	}

	.collage .piece.one,
	.collage .piece.two, 
	.collage .piece.three,
	.collage .piece.six,
	.collage .piece.seven,
	.collage .piece.nine {
		width: calc(100% / 3);
	}

	.collage .piece.one {
		height: calc(60%);
	}
	
	.collage .piece.two {
		height: calc(40%);
	}

	.collage .piece.three {
		height: calc(60%);
	}

	.collage .piece.nine,
	.collage .piece.six {
		bottom: 0;
		height: 40%;
	}

	.collage .piece.seven,
	.collage .piece.two {
		left: calc(100% / 3 * 1)
	}

	.collage .piece.seven {
		height: calc(60%);
		bottom: 0;
	}

	.collage .piece.nine,
	.collage .piece.three {
		left: calc(100% / 3 * 2)
	}
}

